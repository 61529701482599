.searchBar {
    background-image: linear-gradient(rgba(255,255,255,0.75), rgba(255,255,255,0.75)), url("https://images.unsplash.com/photo-1521791136064-7986c2920216?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80");
    background-size: auto;
    background-repeat: repeat-x;
    background-color: #ccffdd;
}

@media only screen and (min-width: 768px) {
    .content {
        width: 70%;
        margin: 0 auto;
    }
}
